import styles from "./styles.module.less"

const Contact = () => {
  return (
    <div className={styles.main}>
      <div>咨询热线:15330066919</div>
    </div>
  )
}

export default Contact